<template>
  <div id="app">
    <div class="header">
      <div class="header__logo-wrapper">
        <router-link aria-label="Startseite" class="header__logo-link" to="/">
          <Logo/>
        </router-link>
      </div>
      <div class="nav" :class="{'nav--light': !stageVisible}">
        <Navigation/>
      </div>
      <button class="mobile-nav-trigger" :class="{'mobile-nav-trigger--light': !stageVisible}" @click="$refs.mobileNav.open()">
        <div class="mobile-nav-trigger__bar"></div>
      </button>
    </div>
    <transition name="slide-fade" mode="out-in">
      <router-view/>
    </transition>
    <div class="footer" :class="{'footer--light': !stageVisible}">
      <LegalLinks/>
    </div>
    <MobileNav ref="mobileNav"/>
  </div>
</template>

<script>
import Logo from '@/components/logo.vue'
import Navigation from '@/components/navigation.vue'
import LegalLinks from '@/components/legal-links.vue'
import MobileNav from '@/components/mobile-nav.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Logo,
    Navigation,
    LegalLinks,
    MobileNav
  },
  computed: {
    ...mapState([
      'stageVisible',
      'OS'
    ])
  },
  created () {
    this.setOs()
  },
  methods: {
    ...mapActions([
      'setOs'
    ])
  }
}
</script>

<style>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all .3s;
}
.slide-fade-enter {
  transform: translateY(-20px);
  opacity: 0;
}
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(20px);
  opacity: 0;
}
</style>
<style lang="scss">
@font-face {
    font-family:'Lato';
    font-display: swap;
    src:
      local('Lato-Light'),          // Erst nachsehen, ob die Schrift
      url('./fonts/Lato-Light.woff') format('woff'), /* Modern Browsers */
      url('./fonts/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
      url('./fonts/Lato-Light.ttf') format('ttf'); // Wenn nicht, wird die Schrift vom Webserver geladen
}
@font-face {
    font-family:'Lato';
    font-weight: bold;
    font-display: swap;
    src:
      local('Lato-Bold'),          // Erst nachsehen, ob die Schrift
      url('./fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
      url('./fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
      url('./fonts/Lato-Bold.ttf') format('ttf'); // Wenn nicht, wird die Schrift vom Webserver geladen
}
@font-face {
    font-family:'Lato';
    font-style: italic;
    font-display: swap;
    src:
      local('Lato-LightItalic'),          // Erst nachsehen, ob die Schrift
      url('./fonts/Lato-LightItalic.woff') format('woff'), /* Modern Browsers */
      url('./fonts/Lato-LightItalic.woff2') format('woff2'), /* Modern Browsers */
      url('./fonts/Lato-LightItalic.ttf') format('ttf'); // Wenn nicht, wird die Schrift vom Webserver geladen
}
@font-face {
    font-family:'Lato';
    font-style: italic;
    font-weight: bold;
    font-display: swap;
    src:
      local('Lato-Italic'),          // Erst nachsehen, ob die Schrift
      url('./fonts/Lato-Italic.woff') format('woff'), /* Modern Browsers */
      url('./fonts/Lato-Italic.woff2') format('woff2'), /* Modern Browsers */
      url('./fonts/Lato-Italic.ttf') format('ttf'); // Wenn nicht, wird die Schrift vom Webserver geladen
}
* {
  box-sizing: border-box;
}
html, body {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  font-size: 13pt;
  hyphens: auto;
}
#app {
  font-family: Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  position: relative;
}

.header {
  display: flex;
  height: auto;
  width: 100%;
  padding: 1rem 1rem 0;
  top: 0;
  align-items: flex-end;
  z-index: 1;
  flex-wrap: wrap;
  &__logo-wrapper {
    display: flex;
    flex: 1;
    height: 50px;
    a {
      width: auto;
      height: 100%;
    }
  }
  .nav {
    margin-top: 1rem;
    display: none;
    a {
      color: #006cb7;
      text-decoration: none;
      margin-left: 2rem;
      &:first-child {
        margin-left: 0;
      }

      &.router-link-exact-active {
        font-weight: bold;
      }
    }
    &--light {
      a {
        color: #006cb7 !important
      }
    }
    @media (min-width: 1440px) {
      display: block;
    }
  }
  @media (min-width: 1024px) {
    height: 100px;
    padding: 2rem 2rem 0;
    position: absolute;
    .nav a {
      color: #ffffff;
    }
    &__logo-wrapper {
      height: 100%;
    }
  }
}

.footer {
  padding: 1rem;
  color: #006cb7;
  display: none;
  a {
      color: #006cb7;
      text-decoration: none;;
      margin-right: 2rem;

      &:last-child {
        margin-right: 0;
      }

      &.router-link-exact-active {
        font-weight: bold;
      }
    }
  @media (min-width: 1440px) {
    display: block;
    position: absolute;
    color: #ffffff;
    bottom: 2rem;
    right: 2rem;
    a {
      color: #ffffff;
    }
  }
  &--light {
    position: relative;
    width: 100%;
    padding: 2rem;
    text-align: right;
    color: #006cb7;
    a {
      color: #006cb7
    }
  }
}
.mobile-nav-trigger {
  display: none;
}
@media (max-width: 1439px) {
  .mobile-nav-trigger {
    display: flex;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    background: transparent;
    outline: none;
    border: none;
    -webkit-appearance: none;
    color: #ffffff;
    &__bar {
      position: relative;
      width: 100%;
      height: 3px;
      background-color: currentColor;
      &:after, &:before {
        background-color: currentColor;
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 3px;
      }
      &:before {
        transform: translate(0, 7px);
      }
      &:after {
        transform: translate(0, 14px);
      }
    }
    &--light {
      color: #006cb7;
    }
  }
}
@media (max-width: 1023px) {
  .mobile-nav-trigger {
    color: #006cb7;
  }
}
</style>
