import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stageVisible: false,
    OS: ''
  },
  mutations: {
    stageVisible (state, val) {
      state.stageVisible = val
    },
    OS (state, val) {
      state.OS = val
    }
  },
  actions: {
    setStageVisible (context, val) {
      context.commit('stageVisible', val)
    },
    setOs (context) {
      const OsArr = [
        {
          contains: 'Win',
          name: 'Windows'
        },
        {
          contains: 'Mac',
          name: 'MacOS'
        }
      ]
      let OS = ''
      OsArr.forEach(elm => {
        if (navigator.appVersion.indexOf(elm.contains) !== -1) OS = elm.name
        else OS = 'other'
      })
      context.commit('OS', OS)
    }
  },
  modules: {
  }
})
