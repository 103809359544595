<template>
    <svg
        class="logo"
        enable-background="new 0 0 1000 1000"
        viewBox="0 0 1000 1000"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="30"
    >
        <path d="m715.5 720.3-219.2-439.7-221.5 439.7z"/>
        <path d="m275 719.7 221.5.6-219.2-439.7-224.4 438.6 140.6.4"/>
        <path d="m796.1 720.3h150.9l-231.2-439.7-219.1 439.7h218"/>
    </svg>
</template>
<script>
export default {
  name: 'Logo'
}
</script>
<style lang="scss" scoped>
    .logo {
        height: 100%;
        width: auto;
        color: #006cb7;
        transform: scaleX(1.3);
        transform-origin: left center;
    }
</style>
