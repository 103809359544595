<template>
    <transition
        name="slide-fade"
        @after-enter="disableScrollLock"
        @leave="enableScrollLock"
    >
        <div
            v-if="isOpen"
            class="mobile-nav"
            ref="mobileNavWrapper"
        >
            <div class="mobile-nav__wrapper">
                <button @click="close" class="mobile-nav__close">
                    <div class="mobile-nav__bar"></div>
                </button>
                <div class="mobile-nav__navigation-wrapper">
                    <Navigation class="mobile-nav__navigation"/>
                    <LegalLinks class="mobile-nav__navigation"/>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Navigation from '@/components/navigation.vue'
import LegalLinks from '@/components/legal-links.vue'
export default {
  name: 'MobileNav',
  components: {
    Navigation,
    LegalLinks
  },
  data () {
    return {
      isOpen: false
    }
  },
  watch: {
    $route () {
      this.isOpen = false
    }
  },
  methods: {
    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    enableScrollLock () {
      enableBodyScroll(this.$refs.mobileNavWrapper)
    },
    disableScrollLock () {
      disableBodyScroll(this.$refs.mobileNavWrapper)
    }
  }
}
</script>
<style lang="scss">
  .mobile-nav {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0,0,0,.3);
      height: 100%;
      z-index: 10;
      backdrop-filter: blur(10px);
      @media (max-width: 1439px) {
        display: block;
      }
      &__close {
          width: 30px;
          height: 30px;
          padding: 0;
          margin: 0;
          background: transparent;
          border: none;
          outline: none;
          -webkit-appearance: none;
          margin: 0 0 1rem auto;
          display: block;
      }
      &__bar {
          width: 30px;
          height: 3px;
          transform: rotate(45deg);
          position: relative;
          background: #333333;
          &:after {
              content: '';
              background: #333333;
              position: absolute;
              top: 0;
              left: 0;
              height: 3px;
              width: 30px;
              transform: rotate(-90deg);
          }
      }
      &__wrapper {
          width: 38.2%;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          padding: 1rem;
          background-color: #ffffff;
          @media (max-width: 1023px) {
            width: 100%;
          }
      }
      &__navigation {
          display: flex;
          flex-direction: column;
          color: #333333;
          a {
              padding: 1rem;
              border-bottom: 1px solid #eeeeee;
              text-decoration: none;
              color: inherit;
          }
      }
}
</style>
